import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "osipov" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Osipov extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Цепкий левый защитник, доигрывающий до конца эпизоды, с неплохой скоростью и&nbsp;навыками плотной опеки оппонента.</p>
          <p>Левоногий, способен сыграть также на позиции левого полузащитника. Координирован, умеет быстро поменять направление движения.</p>
          <p>При необходимости может поддержать игру в середине поля (на позиции левого инсайда), быстро принимает мяч, не задерживается с решениями.</p>
          <p>Тактически грамотный, дисциплинированный футболист, что позволяет ему играть на трех позициях. Уверенно и быстро включается в игру, когда выходит на замены.</p>
        </div>
      </div>
    )
  }
}